<template>
  <v-card tile width="100%">
    <v-card-text class="pa-0">
      <v-data-table
        multi-sort
        :headers="headers"
        :items="users"
        :search="search"
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn :disabled="!admin" :small="$vuetify.breakpoint.lgAndDown" @click="openCreateDialog" text tile>
              <v-icon>mdi-plus</v-icon>
              <span class="ml-1">Add New User</span>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.role="{ item }">
          <v-chip color="primary" dark small>{{ item.role.role | capitalizeFirstLetter }}</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn class="mr-2" small icon @click="openEditDialog(item)">
            <v-icon
              small
            >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn class="mr-2" small icon @click="openConfirmationForDeleting(item.id)">
            <v-icon
              small
            >
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog max-width="320" v-model="create.show">
      <validation-observer ref="createUserObserver" v-slot="{ passes }">
        <v-form @submit.prevent="passes(submit)">
          <v-card>
            <v-card-text>
              <validation-provider name="user name" rules="required" v-slot="{ errors }">
                <v-text-field v-model="create.item.name" placeholder="Set new user name"
                              :error-messages="errors"
                              counter
                              label="New user name"></v-text-field>
              </validation-provider>
              <validation-provider name="user e-mail" rules="required|email" v-slot="{ errors }">
                <v-text-field v-model="create.item.email" placeholder="Set new user e-mail"
                              :error-messages="errors"
                              counter
                              label="New user e-mail"></v-text-field>
              </validation-provider>
              <validation-provider name="user e-mail" rules="required|confirmed:confirmation" v-slot="{ errors }">
                <v-text-field v-model="create.item.password"
                              :error-messages="errors"
                              type="password" placeholder="Set new user password"
                              label="User password"></v-text-field>
              </validation-provider>
              <validation-provider name="password confirmation" vid="confirmation" v-slot="{ errors }">
                <v-text-field v-model="create.item.password_confirmation"
                              :error-messages="errors"
                              type="password" placeholder="Set new user password"
                              label="User password"></v-text-field>
              </validation-provider>
              <validation-provider name="user role" rules="required" v-slot="{ errors }">
                <v-select :items="roles" v-model="create.item.role" placeholder="Set new user role"
                          :error-messages="errors"
                          label="New user role"></v-select>
              </validation-provider>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <v-btn type="button" @click="closeCreateDialog">Cancel</v-btn>
              <v-btn type="submit" color="primary">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>
    <template v-if="edit.show">
      <v-dialog max-width="320" v-model="edit.show">
        <validation-observer ref="editUserObserver" v-slot="{ passes }">
          <v-form @submit.prevent="passes(update)">
            <v-card>
              <v-card-text>
                <validation-provider name="user name" rules="required" v-slot="{ errors }">
                  <v-text-field v-model="edit.item.name" placeholder="Edit user name"
                                :error-messages="errors"
                                counter
                                label="User name"></v-text-field>
                </validation-provider>
                <validation-provider name="user e-mail" rules="required|email" v-slot="{ errors }">
                  <v-text-field v-model="edit.item.email" placeholder="Edit user e-mail"
                                :error-messages="errors"
                                counter
                                label="User e-mail"></v-text-field>
                </validation-provider>
                <v-text-field v-model="edit.item.password" type="password" placeholder="Set new user password"
                              label="User password"></v-text-field>
                <validation-provider name="user role" rules="required" v-slot="{ errors }">
                  <v-select :items="roles" v-model="edit.item.role.role" placeholder="Edit user role"
                            :error-messages="errors"
                            label="User role"></v-select>
                </validation-provider>
              </v-card-text>
              <v-card-actions class="d-flex justify-end">
                <v-btn type="button" @click="closeEditDialog">Cancel</v-btn>
                <v-btn type="submit" color="primary">Update</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-dialog>
    </template>
    <starboxx-confirmation
      :action="{data: id, message: 'Do you really want to delete this user?', name: 'delete-user'}"/>
  </v-card>
</template>

<script>
export default {
  name: 'Subscriber',
  data () {
    return {
      loading: null,
      search: '',
      users: [],
      roles: [
        {
          text: 'Admin',
          value: 'admin'
        },
        {
          text: 'Manager',
          value: 'manager'
        }
      ],
      id: null,
      edit: {
        show: false,
        item: {
          role: {
            role: null
          }
        }
      },
      create: {
        show: false,
        item: {}
      },
      headers: [
        { text: 'User Name', value: 'name', sortable: true },
        { text: 'User E-mail', value: 'email', sortable: true },
        { text: 'User Role', value: 'role', sortable: true },
        { text: 'Created At', value: 'date', sortable: true },
        { text: 'Actions', align: 'center', value: 'actions', sortable: false }
      ]
    }
  },
  mounted () {
    this.loading = 'danger'
    this.getUsers()
    this.$root.$on('delete-user', (id) => {
      this.deleteUser(id)
    })
  },
  methods: {
    getUsers () {
      this.$http.get(`${process.env.VUE_APP_API_URL}/api/users`)
        .then(response => {
          this.users = response.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    openConfirmationForDeleting (id) {
      this.id = id
      this.$store.dispatch('setConfirmation', true)
    },
    deleteUser (id) {
      this.$http.delete(`${process.env.VUE_APP_API_URL}/api/users/${id}`)
        .then(() => {
          this.getUsers()
        })
    },
    openEditDialog (item) {
      this.edit.item = item
      this.edit.show = true
    },
    openCreateDialog () {
      this.create.show = true
    },
    closeEditDialog () {
      this.edit.show = false
      this.edit.item = {}
    },
    closeCreateDialog () {
      this.create.show = false
      this.create.item = {}
    },
    update () {
      this.$http.patch(`${process.env.VUE_APP_API_URL}/api/users/${this.edit.item.id}`, this.edit.item)
        .then(() => {
          this.closeEditDialog()
          this.$refs.editUserObserver.reset()
          this.getUsers()
        })
    },
    submit () {
      this.$http.post(`${process.env.VUE_APP_API_URL}/api/users`, this.create.item)
        .then(() => {
          this.closeCreateDialog()
          this.$refs.createUserObserver.reset()
          this.getUsers()
        })
    }
  },
  computed: {
    admin () {
      return this.$store.getters.GET_USER.role.role === 'admin'
    }
  }
}
</script>

<style scoped>

</style>
