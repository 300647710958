var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","width":"100%"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"multi-sort":"","headers":_vm.headers,"items":_vm.users,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.admin,"small":_vm.$vuetify.breakpoint.lgAndDown,"text":"","tile":""},on:{"click":_vm.openCreateDialog}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',{staticClass:"ml-1"},[_vm._v("Add New User")])],1)],1)]},proxy:true},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","dark":"","small":""}},[_vm._v(_vm._s(_vm._f("capitalizeFirstLetter")(item.role.role)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openConfirmationForDeleting(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}])})],1),_c('v-dialog',{attrs:{"max-width":"320"},model:{value:(_vm.create.show),callback:function ($$v) {_vm.$set(_vm.create, "show", $$v)},expression:"create.show"}},[_c('validation-observer',{ref:"createUserObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.submit)}}},[_c('v-card',[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"user name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Set new user name","error-messages":errors,"counter":"","label":"New user name"},model:{value:(_vm.create.item.name),callback:function ($$v) {_vm.$set(_vm.create.item, "name", $$v)},expression:"create.item.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"user e-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Set new user e-mail","error-messages":errors,"counter":"","label":"New user e-mail"},model:{value:(_vm.create.item.email),callback:function ($$v) {_vm.$set(_vm.create.item, "email", $$v)},expression:"create.item.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"user e-mail","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"password","placeholder":"Set new user password","label":"User password"},model:{value:(_vm.create.item.password),callback:function ($$v) {_vm.$set(_vm.create.item, "password", $$v)},expression:"create.item.password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password confirmation","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"password","placeholder":"Set new user password","label":"User password"},model:{value:(_vm.create.item.password_confirmation),callback:function ($$v) {_vm.$set(_vm.create.item, "password_confirmation", $$v)},expression:"create.item.password_confirmation"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"user role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.roles,"placeholder":"Set new user role","error-messages":errors,"label":"New user role"},model:{value:(_vm.create.item.role),callback:function ($$v) {_vm.$set(_vm.create.item, "role", $$v)},expression:"create.item.role"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"type":"button"},on:{"click":_vm.closeCreateDialog}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"type":"submit","color":"primary"}},[_vm._v("Save")])],1)],1)],1)]}}])})],1),(_vm.edit.show)?[_c('v-dialog',{attrs:{"max-width":"320"},model:{value:(_vm.edit.show),callback:function ($$v) {_vm.$set(_vm.edit, "show", $$v)},expression:"edit.show"}},[_c('validation-observer',{ref:"editUserObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.update)}}},[_c('v-card',[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"user name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Edit user name","error-messages":errors,"counter":"","label":"User name"},model:{value:(_vm.edit.item.name),callback:function ($$v) {_vm.$set(_vm.edit.item, "name", $$v)},expression:"edit.item.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"user e-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Edit user e-mail","error-messages":errors,"counter":"","label":"User e-mail"},model:{value:(_vm.edit.item.email),callback:function ($$v) {_vm.$set(_vm.edit.item, "email", $$v)},expression:"edit.item.email"}})]}}],null,true)}),_c('v-text-field',{attrs:{"type":"password","placeholder":"Set new user password","label":"User password"},model:{value:(_vm.edit.item.password),callback:function ($$v) {_vm.$set(_vm.edit.item, "password", $$v)},expression:"edit.item.password"}}),_c('validation-provider',{attrs:{"name":"user role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.roles,"placeholder":"Edit user role","error-messages":errors,"label":"User role"},model:{value:(_vm.edit.item.role.role),callback:function ($$v) {_vm.$set(_vm.edit.item.role, "role", $$v)},expression:"edit.item.role.role"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"type":"button"},on:{"click":_vm.closeEditDialog}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"type":"submit","color":"primary"}},[_vm._v("Update")])],1)],1)],1)]}}],null,false,4000661917)})],1)]:_vm._e(),_c('starboxx-confirmation',{attrs:{"action":{data: _vm.id, message: 'Do you really want to delete this user?', name: 'delete-user'}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }